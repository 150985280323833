<template>
  <section class="team">
    <div class="container">
      <div class="team-wrap">
        <ElementHeading title="Team" />

        <div
          class="team-slider"
          v-touch:swipe.left="nextSlide"
          v-touch:swipe.right="prevSlide"
          v-if="isSliderShow">
          <Transition name="slide-fade">
            <div
              v-if="show"
              class="team-item">
              <div class="team-item-avatar">
                <img :src="require(`@/assets/images/team/png/${team[counter].src}.png`)" alt="">
              </div>

              <div class="team-item-info">
                <p>
                  {{ team[counter].name }}
                </p>
                <span>
                  {{ team[counter].role }}
                </span>
                <button>
                  <router-link
                    :to="{ name: 'view-team', params: { id: team[counter].src } }">
                    Info
                  </router-link>
                </button>
              </div>
            </div>
          </Transition>

          <div
            v-if="isSliderShow"
            class="team-slider-dots">
            <span
              v-for="(m, index) in team"
              :class="[index === counter ? dotActiveClass : '']"
              :key="m.id"
              @click="dotClick(index)">
            </span>
          </div>

          <div
            v-if="isSliderShow"
            @click="nextSlide"
            class="team-slider-arrow">
            <svg width="100%" height="100%" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.1006 5.20117L20.293 14.3936C20.6835 14.7841 20.6835 15.4172 20.293 15.8078L11.1006 25.0002"
                stroke-width="2" stroke-linecap="round" />
            </svg>
          </div>
        </div>

        <div
          class="team-items"
          v-else>
          <div
            class="team-item"
            v-for="{ name, id, src, role } in team"
            :key="id">
            <div class="team-item-avatar">
              <img :src="require(`@/assets/images/team/png/${src}.png`)" :alt="name">
            </div>

            <div class="team-item-info">
              <p>
                {{ name }}
              </p>
              <span>
                {{ role }}
              </span>
              <button>
                <router-link
                  :to="{ name: 'view-team', params: { id: src } }">
                  Info
                </router-link>
              </button>
            </div>
          </div>
        </div>

        <span class="light light-blue team-light-blue-left"></span>
        <span class="light light-blue team-light-blue-right"></span>
        <span class="light light-yellow"></span>
      </div>
    </div>
  </section>
</template>

<script>
import ElementHeading from '@/components/elements/element-heading'
export default {
  name: 'main-team',
  components: {
    ElementHeading
  },
  data: () => {
    return {
      counter: 0,
      isSliderShow: false,
      dotActiveClass: 'active-dot',
      show: true,
      team: [],
      
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.team = this.$store.state.team
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.isSliderShow = window.innerWidth <= 520;
    },
    dotClick(i) {
      this.counter = i
      this.slideAnimation()
    },
    nextSlide() {
      if (this.counter < this.team.length - 1) this.counter++
      else this.counter = 0
      this.slideAnimation()
    },
    prevSlide() {
      if (this.counter > 0) this.counter--
      else this.counter = this.team.length - 1
      this.slideAnimation()
    },
    slideAnimation() {
      this.show = !this.show
      setTimeout(() => {
        this.show = !this.show
      }, 300)
    }
  },
}
</script>


<style>
.team {
  padding: 8vw 0;
}

.team-items {
  max-width: 1244px;
  margin: 4vw auto 0;
  display: grid;
  position: relative;
  z-index: 20;
  grid-template-columns: repeat(3, max-content);
  grid-template-rows: repeat(3, 1fr);
  grid-auto-rows: auto;
  row-gap: 5.5rem;
  column-gap: 1.5rem;
  justify-content: space-between;
}

.team-item {
  display: grid;
  grid-template-columns: 8.3rem 1fr;
  height: 9.4rem;
  column-gap: 1.07rem;
}

.team-item-avatar {
  position: relative;
  background: center / contain no-repeat url('../../assets/images/team/cell-desk.png');
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-item-avatar img {
  width: 60%;
  height: auto;
  object-fit: contain;
}

.team-item-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.team-item-info span,
.team-item-info p {
  font-weight: 700;
  font-size: 1.13rem;
  text-transform: capitalize;
  line-height: 1;
}

.team-item-info span {
  margin-top: .08rem;
  color: var(--special-color);
}

.team-item-info button {
  margin-top: .4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 3.45rem;
  height: 1.8rem;
  background: var(--special-color);
  border-radius: .3rem;
  border: none;
  cursor: pointer;
}

.team-item-info button a {
  font-weight: 700;
  font-size: .875rem;
  color: #272838;
}

.team-slider .team-item {
  margin-bottom: 6vw;
  grid-template-columns: 8.3rem 12rem;
  justify-content: unset;
}

.team-slider {
  position: relative;
  margin-top: 9vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 11.4rem;
  ;
}

.team-slider-dots {
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vw;
}

.team-slider-dots span {
  background: rgba(255, 221, 44, 0.12);
  border: .5vw solid rgba(255, 221, 44, 0.24);
  border-radius: 100%;
  width: 4vw;
  height: 4vw;
}

.team-slider-dots span.active-dot {
  background: #1C1C1C;
  border: .8vw solid var(--special-color);
}

.team-slider-arrow {
  position: absolute;
  width: 1.875rem;
  height: 1.875rem;
  top: 50%;
  right: 4vw;
  transform: translate(-50%, -50%);
}

.team-slider-arrow svg path {
  stroke: var(--special-color);
}

.team .light.light-blue.team-light-blue-right {
  width: 26vw;
  height: 40vw;
  top: 273px;
  left: 82%;
}

.team .light.light-blue.team-light-blue-left {
  width: 22vw;
  height: 22vw;
  left: -109px;
  top: -133px;
}

.team .light.light-yellow {
  width: 10vw;
  height: 10vw;
  left: 0;
  top: -60%;
}

@media(max-width: 520px) {
  .team-slider .team-item {
    margin-left: 2rem;
    grid-template-columns: 8.3rem 8.3rem;
  }

  .team-item-info span {
    height: 2.26rem;
  }
}
</style>