<template>
  <main class="team-single">
    <div class="container">
      <div class="team-single-wrap">
        <div class="team-single-card" v-if="member">
          <div class="team-single-avatar">
            <img :src="require(`@/assets/images/team/png/${member.src}.png`)" alt="">
          </div>
          <h4>{{ member.name }}</h4>
          <span>{{ member.role }}</span>
          <p> {{ member.description }} </p>
        </div>
        <span class="light light-blue"></span>
        <span class="light light-orange"></span>
        <span class="light light-yellow"></span>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'view-team',
  data() {
    return {
      id: this.$route.params['id'],
      item: null,
      team: []
    }
  },
  watch: {
    $route(toRoute) {
      this.id = toRoute.params['id']
    }
  },
  computed: {
    member() {
      return this.team.find(i => i.src === this.id)
    }
  },
  created() {
    this.team = this.$store.state.team
  },
  mounted(){
    document.title = 'Team — BeePro'
  },
}
</script>

<style>
.team-single {
  padding: 20.5rem 0 0;
  margin-bottom: 160px;
}

.team-single-wrap {
  max-width: 1150px;
  margin: 0 auto;
}

.team-single-card {
  position: relative;
  z-index: 20;
  padding: 7.75rem 5.7rem 5.6rem 1.8rem;
  background: radial-gradient(88.05% 200.61% at 1.95% 92.59%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .4) 100%);
  backdrop-filter: blur(40px);
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  min-height: 459px;
}

.team-single-card-sup {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.team-single-avatar {
  position: absolute;
  background: center / contain no-repeat url('../assets/images/team/cell.png');
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10.25rem;
  height: 10.25rem;
  transform: translateY(-50%);
  top: 0;
  left: 1.25rem;
  z-index: 30;
}

.team-single-avatar img {
  width: 55%;
  height: auto;
  object-fit: contain;
}

.team-single-card h4 {
  font-weight: 700;
  font-size: 1.94rem;
  text-transform: capitalize;
}

.team-single-card span {
  margin-top: .25rem;
  font-weight: 700;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.66);
}

.team-single-card p {
  margin-top: 2rem;
  font-weight: 400;
  font-size: 1.43rem;
  line-height: 120%;
}

.team-single .light-blue {
  width: 27vw;
  height: 27vw;
  left: 47%;
  top: 58%;
  transform: translate(-50%, -50%);
  opacity: 1;
  filter: blur(100px);
}

.team-single .light-orange {
  width: 25vw;
  height: 25vw;
  left: 8vw;
  top: 25%;
  filter: blur(130px);

}

.team-single .light-yellow {
  width: 26vw;
  height: 16vw;
  left: 71%;
  top: 61%;
  transform: translate(-50%, -50%);
  filter: blur(120px);
}
</style>
