<template>
  <header>
    <div class="container">
      <div
        class="burger-btn"
        @click="openMenu">
        <div class="burger">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="header-wrap">
        <ElementLogo />
        <nav>
          <ul class="menu">
            <li class="menu-item">
              <router-link to='/about'>About</router-link>
            </li>

            <li class="menu-item">
              <router-link to='/products'>Products</router-link>
            </li>

            <li class="menu-item">
              <router-link class="local-link" :to="{ name: 'view-main', hash: '#collections' }">NFT and Game</router-link>
            </li>

            <li class="menu-item">
              <router-link class="local-link" :to="{ name: 'view-main', hash: '#community' }">Community</router-link>
            </li>

            <li class="menu-item">
              <div class="dropdown-menu" @click="showEarnMenu">
                <span v-bind:class="[isDocSubmenuOpen ? linkActiveClass : '']"
                  href="">
                  Documents
                </span>
                <svg
                  class="dropdown-arrow"
                  v-bind:class="[isDocSubmenuOpen ? dropdownArrowActiveClass : '']"
                  width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.99998 7.60964L0.195312 1.80497L1.13798 0.862305L5.99998 5.7243L10.862 0.862305L11.8046 1.80497L5.99998 7.60964Z" />
                </svg>
              </div>
              <Transition name="slide-fade">
                <div
                  v-if="isDocSubmenuOpen === true"
                  class="earn-menu">
                  <ul>
                    <li>
                      <a href="https://drive.google.com/file/d/1gO15Wy78pm2SKwE5ocPRPQJr686MEwPL/view">Whitepaper</a>
                    </li>
                    <li>Gitbook (Soon)</li>
                  </ul>
                </div>
              </Transition>
            </li>

            <li class="menu-item connect">
              <!-- <button
                class="connect-btn"
                @click="openWallet">
                <span>Connect</span>
                <span> Wallet</span>
              </button> -->
            </li>

            <li class="menu-item login">
              <!-- <ButtonRegular /> -->
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>

// import ButtonRegular from '@/components/buttons/button-regular'
import ElementLogo from '@/components/elements/element-logo'
export default {
  name: 'block-header',
  components: {
    // ButtonRegular,
    ElementLogo,
  },
  data: () => {
    return {
      isShowEarn: false,
      dropdownArrowActiveClass: 'dropdown-arrow_active',
      linkActiveClass: 'link_active',
      isDocSubmenuOpen: false,
      isEarnSubmenuOpen: false,
    }
  },
  methods: {
    showEarnMenu() {
      this.isDocSubmenuOpen = !this.isDocSubmenuOpen
    },
    openWallet() {
      this.$emit('open-wallet', true)
    },
    openMenu() {
      this.$emit('open-menu', true)
    }
  }
}
</script>

<style>
header {
  position: absolute;
  top: 0;
  z-index: 40;
  width: 100%;
  padding: 32px 0 0;
}

header .container {
  display: flex;
  align-items: center;
}

header .header-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header nav ul.menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

header nav li.menu-item {
  margin: 0 20px;
  cursor: pointer;
  position: relative;
}

header nav li.menu-item span,
nav li.menu-item a {
  font-weight: 500;
}


.connect-btn {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: inset 0.924667px -0.924667px 0.924667px rgba(214, 214, 214, 0.4), inset -0.924667px 0.924667px 0.924667px rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(9.7px);
  width: 118px;
  height: 22px;
  border-radius: 7.7px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.connect-btn span {
  font-weight: 700;
  font-size: 0.625rem;
  letter-spacing: 0.03px
}

.connect-btn span:nth-child(2) {
  margin-left: 3px;
}

.login {
  padding: 0 0 0 30px;
}

.burger-btn {
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}

.burger {
  width: 16px;
  height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.burger span {
  height: 2px;
  background: var(--color-white);
}

.burger span:nth-child(1) {
  width: 16px;
}

.burger span:nth-child(2) {
  width: 12px;
}

.burger span:nth-child(3) {
  width: 6px;
}

header .earn-menu {
  width: max-content;
  height: max-content;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  /* transform: translateY(50%); */
  bottom: -3.3rem;
  background: radial-gradient(98.05% 261.61% at 1.95% 3.59%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  ;
  backdrop-filter: blur(40px);
  border-radius: 9px;
  padding: 7px;
}

header .earn-menu ul {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: space-between;
  height: 100%;
}

header .earn-menu ul li {
  font-weight: 500;
  font-size: 1rem;
  line-height: 26px;
  color: #FFFFFF;
}
</style>
