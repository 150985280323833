<template>
  <main class="about">
    <BlockHero :isSmallImg="true">
      <div class="about-bee"></div>
    </BlockHero>
    <section class="about-info">
      <div class="container">
        <div class="about-info-wrap">
          <p> BEEPRO: MODERN PLATFORM WITH UNIQUE TECHNOLOGY We have used the main achievements in the field of virtual
            asset trading to create a convenient and multifunctional platform. </p>
          <br class="desk-br">
          <br>
          <p>We try to find answers to those questions that concern users the most:</p>
          <br class="desk-br">
          <br>
          <p>• HOW TO MAKE SURE THAT THE TOKEN IS RELIABLE AND SECURE?</p>
          <p>Our Beendexer technology provides you with information obtained from the analysis of the blockchain network
            about the reliability and development prospects of each token. Having the entire history of use of the
            cryptocurrency, it highlights patterns that correspond to successful projects.</p>
          <br class="desk-br">
          <br>
          <p>Beendexer can be compared to the which, however, does not turn to the god Apollo for a prediction, but to
            the statistics of ownership and the database of executed orders.</p>
          <br>
          <p>• HOW TO SAVE YOUR PRIVACY?</p>
          <p>Decentralized platforms, to which BEEPRO belongs, allow you to open a personal account at the address of an
            existing crypto wallet without the need to register. Transactions are carried out anonymously and without
            intermediaries.</p>
          <br class="desk-br">
          <br>
          <p>• HOW TO FIND NEW INTERESTING PROJECTS?</p>
          <p>You can consider purchasing a BEEPRO or NFT coin from our collections! We are actively working on new ways
            to support and encourage users: one of threm will be in-platform mini-games where you can earn valuable
            prizes and, in addition, evaluate the existing capabilities of Beendexer technology.</p>
          <br class="desk-br">
          <br>
          <p>That's all, but only for now. Follow the updates on our website and social networks</p>
        </div>
      </div>
    </section>
    <section class="about-contacts">
      <div class="container">
        <div class="about-contacts-wrap">
          <ul class="about-contact-items">
            <li>
              <a href="https://t.me/BeePro_chat">
                <img :src="telegram" alt="telegram">
              </a>
            </li>
            <li>
              <a href="https://discord.com/invite/5BCv3dXRPw">
                <img :src="discord" alt="discord">
              </a>
            </li>
            <li>
              <a href="https://twitter.com/beeprodex">
                <img :src="twitter" alt="twitter">
              </a>
            </li>
            <li>
              <a href="https://instagram.com/beepro.dex">
                <img :src="instagram" alt="instagram">
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCl-m3A8BkoygIQz7wRUDv_A">
                <img :src="youtube" alt="youtube">
              </a>
            </li>
            <!-- <li>
              <a href="">
                <img :src="reddit" alt="reddit">
              </a>
            </li> -->
          </ul>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import BlockHero from '@/components/blocks/block-hero'

import blackGreenYellowFrontBee from '@/assets/images/bees/mobile/animated/black-green-yellow-front-bee.webp'
import telegram from '@/assets/icons/about/telegram.svg'
import discord from '@/assets/icons/about/discord.svg'
import twitter from '@/assets/icons/about/twitter.svg'
import instagram from '@/assets/icons/about/instagram.svg'
import youtube from '@/assets/icons/about/youtube.svg'
import reddit from '@/assets/icons/about/reddit.svg'

export default {
  name: 'view-about',
  components: {
    BlockHero,
  },
  data: () => {
    return {
      blackGreenYellowFrontBee,
      telegram,
      discord,
      twitter,
      instagram,
      youtube,
      reddit,
    }
  },
  mounted(){
    document.title = 'About — BeePro'
  }
}
</script>

<style>
.about-info {
  padding-top: 14em;
  z-index: 20;
  position: relative;
}
.about-info-wrap,
.about-contacts-wrap {
  max-width: 1100px;
  margin: auto;
}
.about-info p {
  font-weight: 400;
  font-size: 1.7vw;
  line-height: 150%;
  letter-spacing: 0.025em;
  color: var(--white-color);
}
.about-contacts {
  padding: 14rem 0 6em;
}
.about-contact-items {
  max-width: 50%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 4vw;
  gap: 3.2vw;
}
.about-contact-items li {
  width: 100%;
  height: 100%;
}
.about-contact-items img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
@media(max-width: 1100px) {
  .desc-br {
    display: none;
  }
}
.about-bee {
  width: 600px;
  height: 600px;
  position: absolute;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 100%;
}
.about-bee::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  background: center / contain no-repeat url("../assets/images/bees/mobile/animated/black-green-yellow-front-bee.webp");
}
.about .hero-block {
  height: 28rem;
}
@media(max-width: 600px) {
  .hero-block .hero-wrap {
    padding: 8vw 0 100px;
  }
  .about-bee {
    width: 300px;
    height: 300px;
    position: relative;
    margin: 0 auto;
    transform: unset;
    left: auto;
    top: 0;
  }
}
@media(max-width: 520px) {
  .about-bee {
    animation: up-down-hero-blok alternate 7s;
    animation-iteration-count: alternate;
    transform-origin: 50% 50%;
    -webkit-animation: up-down alternate 7s;
    -webkit-animation-iteration-count: infinite;
    -webkit-transform-origin: 50% 50%;
    -moz-animation: up-down alternate 7s;
    -moz-animation-iteration-count: infinite;
    -moz-transform-origin: 50% 50%;
    -o-animation: up-down alternate 7s;
    -o-animation-iteration-count: infinite;
    -o-transform-origin: 50% 50%;
    -ms-animation: up-down alternate 7s;
    -ms-animation-iteration-count: infinite;
    -ms-transform-origin: 50% 50%;
  }
}
</style>
