<template>
  <section class="collection-block">
    <div class="container">
      <div class="collection-block-wrap">
        <ElementHeading 
          :title="heading.title" 
          :btnText="heading.btnText"
        />
        <div 
          class="cards cards-slider" 
          v-touch:swipe.left="nextSlide" 
          v-touch:swipe.right="prevSlide" 
          v-if="isSliderShow"
          >
          <Transition name="slide-fade">
            <ElementCard 
               v-if="show"
              :collection="collections[counter]"
            />
          </Transition>
        </div>
        <div class="cards" v-else>
          <ElementCard 
            v-for="c in collections" 
            :key="c.id" 
            :collection="c" />
        </div>
        <div v-if="isSliderShow" class="collection-block-dots">
          <span 
            v-for="(c, index) in collections" 
            :class="[index === counter ? dotActiveClass : '']" 
            :key="c.id"
            @click="dotClick(index)">
          </span>
        </div>
        <div v-if="isSliderShow" 
          @click="nextSlide" 
          class="collection-block-arrow">
          <svg width="100%" height="100%" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.1006 5.20117L20.293 14.3936C20.6835 14.7841 20.6835 15.4172 20.293 15.8078L11.1006 25.0002"
              stroke-width="2" stroke-linecap="round" />
          </svg> 
        </div>
        <div 
          v-if="hasButton" 
          class="button-wrap"
          >
          <ButtonRegular text="Buy and play" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ElementHeading from '@/components/elements/element-heading'
import ElementCard from '@/components/elements/element-card'

import ButtonRegular from '@/components/buttons/button-regular'


export default {
  name: 'block-collection',
  components: {
    ElementHeading,
    ElementCard,
    ButtonRegular,
  },
  data: () => {
    return {
      collections: [
        {
          id: 1,
          name: "Common",
          autor: "Yerikho Christian",
          price: "coming soon",
          time: "coming soon",
          src: 'common.png',
        },
        {
          id: 2,
          name: "mythical",
          autor: "Yerikho Christian",
          price: "coming soon",
          time: "coming soon",
          src: 'mythical.png',
        },
        {
          id: 3,
          name: "Immortal",
          autor: "Yerikho Christian",
          price: "coming soon",
          time: "coming soon",
          src: 'immortal.png',
        },
      ],
      counter: 0,
      isSliderShow: false,
      dotActiveClass: 'active-dot',
      show: true
    };
  },
  props: {
    heading: {
      type: Object,
      default() {
        return {
          title: "NFT Collection",
          btnText: "Show more",
        };
      },
    },
    hasButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      if (window.innerWidth <= 720) this.isSliderShow = true
      else this.isSliderShow = false
    },
    dotClick(i) {
      this.counter = i
      this.slideAnimation()
    },
    nextSlide() {
      if (this.counter < this.collections.length - 1) this.counter++
      else this.counter = 0
      this.slideAnimation()
    },
    prevSlide() {
      if (this.counter > 0) this.counter--
      else this.counter = this.collections.length - 1
      this.slideAnimation()
    },
    slideAnimation() {
      this.show = !this.show
      setTimeout(()=> {
        this.show = !this.show
      }, 300)
    }
  },
};
</script>

<style>
.collection-block .cards {
  height: 100%;
  margin: 3vw auto 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 1.4rem;
}

.collection-block .bee-wrap {
  width: 34vw;
  height: 34vw;
  top: 24%;
  right: 20vw;
  position: absolute;
  transform: translate(50%, -50%);
}

.collection-block .cards.cards-slider {
  height: 31.3rem;
}

.collection-block .button-wrap {
  position: relative;
  margin-top: 4vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.collection-block-dots {
  margin: 4vw auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5vw;
}

.collection-block-dots span {
  background: rgba(255, 221, 44, 0.12);
  border: .5vw solid rgba(255, 221, 44, 0.24);
  border-radius: 100%;
  width: 3vw;
  height: 3vw;
}

.collection-block-dots span.active-dot {
  background: #1C1C1C;
  border: .8vw solid var(--special-color);
}

.collection-block-arrow {
  position: absolute;
  width: 1.875rem;
  height: 1.875rem;
  top: 50%;
  right: 6rem;
  transform: translate(-50%, -50%);
}

.collection-block-arrow svg path {
  stroke: var(--special-color);
}
</style>