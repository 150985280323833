<template>
  <div class="block-heading">
    <h3 class="title">{{ title }}</h3>
    <ButtonOval :text="btnText" />
  </div>
</template>

<script>
import ButtonOval from '@/components/buttons/button-oval'

export default {
  name: 'element-heading',
  components: {
    ButtonOval
  },
  props: {
    title: {
      type: String,
    },
    btnText: {
      type: String,
    },

  }
}
</script>

<style>
.block-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 30;
}

.title {
  font-weight: 700;
  font-size: var(--fz-48);
  line-height: 130%;
  text-transform: capitalize;
  font-feature-settings: "pnum" on, "lnum" on;
}
</style>