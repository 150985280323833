<template>
 <div class="search">
  <h2>Explore Now <br> & Get Experience</h2>
  <div class="search-bar">
   <input type="text" placeholder="Search">
   <ButtonRegular text="Search Now"/>
   <img class="bee-left" :src="beeYellow">
   <img class="bee-right" :src="beeNeon">
  </div>
 </div>
</template>

<script>
import ButtonRegular from '@/components/buttons/button-regular';

import beeYellow from '@/assets/images/bee-products-left.png'
import beeNeon from '@/assets/images/bee-products-right.png'

export default {
 name: 'element-search',
 components: {
  ButtonRegular
 },
 data() {
  return {
   beeYellow,
   beeNeon
  }
 }
}
</script>

<style>
 .search {
  padding-top: 6.87rem;
  position: relative;
 }

 .search h2 {
  font-weight: 700;
  font-size: 4rem;
  line-height: 140%;
  text-transform: capitalize;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #FFFFFF;
 }

 .search .search-bar {
  margin-top: 3.5rem;
  width: 100%;
  display: grid;
  height: 56px;
  grid-template-columns: auto max-content;
  gap: 1rem
 }

 .search input {
  height: 100%;
  background: var(--color-white);
  border-radius: 8px;
  border: none;
  font-weight: 500;
  line-height: 160%;
  color: var(--text-input-color);
  padding: 15px 16px;
  position: relative;
  z-index: 20;
 }

 .search .bee-left, .search .bee-right {
  bottom: -450px;
  position: absolute;
 } 

 .search .bee-left {
  left: 0;
  width: 17.9rem;
  height: auto;
 }

 .search .bee-right {
  right: 0;
  width: 18rem;
  height: auto;
 }
</style>