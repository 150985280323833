<template>
	<button @click="clickHandler">
		<img :src="require(`@/assets/icons/wallet/${wallet}.png`)"
			alt="metamask" />
		<span> {{ wallet }} </span>
	</button>
</template>

<script>
export default {


	name: 'button-wallet',
	props: ['wallet'],
	methods: {
		clickHandler() {
			let walletType = this.wallet
			let wallets = [
				{ name: 'metamask', objName: 'ethereum', check: 'isMetaMask', url: 'https://metamask.io/download/' },
				{ name: 'binance', objName: 'BinanceChain', url: 'https://bnbchain.org/en/binance-wallet' },
				{ name: 'coinbase', objName: 'ethereum', check: 'isCoinbaseWallet', url: 'https://www.coinbase.com/wallet/downloads' },
			]

			const wallet = wallets.find(x => x.name === walletType)
			if (!wallet) console.error('Unknown wallet')

			this.connectWallet(wallet)
		},
		async connectWallet(wallet) {
			let provider = window[wallet.objName]

			if (wallet.objName !== 'BinanceChain') {
				provider = window[wallet.objName].providers.find(x => x[wallet.check])
			}

			if (typeof provider === 'undefined') {
				window.location.replace(wallet.url)
			}

			try {
				provider.request({
					method: 'eth_requestAccounts'
				})
			} catch (err) {
				console.log(err)
			}
		}
	}
}
</script>

<style>

</style>