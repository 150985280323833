<template>
 <footer>
   <div>
    <ElementLogo />
   </div>
   <div>
    <ul>
     <li>
      <span>About Us</span>
     </li>
     <li>
      <router-link to='/about'>About Us</router-link>
     </li>
     <li>
      <a>Our Ecosystem</a>
     </li>
     <li>
      <a>Become a Partner</a>
     </li>
    </ul>

    <ul>
     <li>
      <span>Community</span>
     </li>
     <li>
      <a href="https://www.pinksale.finance/private-sale/0xc6A4abaeeE98b68640F4F5d0CDc7E741b3B64F68/?chain=BSC">Token</a>
     </li>
     <li>
      <a>Discussion</a>
     </li>
     <li>
      <a>Voting</a>
     </li>
     <li>
      <a href="https://t.me/Dexbeepro">Blog</a>
     </li>
     <li>
      <a href="https://t.me/BeePRO_support">Help Center</a>
     </li>
    </ul>

    <ul>
     <li>
      <span>Social Media</span>
     </li>
     <li>
      <a href="https://instagram.com/beepro.dex">Instagram</a>
     </li>
     <!-- <li>
      <a>Facebook</a>
     </li> -->
     <li>
      <a href="https://twitter.com/beeprodex">Twitter</a>
     </li>
     <li>
      <a href="https://t.me/BeePro_chat">Telegram</a>
     </li>
     <li>
      <a href="https://discord.com/invite/5BCv3dXRPw">Discord</a>
     </li>
     <li>
      <a href="https://www.youtube.com/channel/UCl-m3A8BkoygIQz7wRUDv_A">Youtube</a>
     </li>
    </ul>
   </div>
   <span class="light light-orange"></span>
   <span class="light light-yellow"></span>
   <span class="light light-blue"></span>
   <div class="bee-wrap"></div>
 </footer>
</template>


<script>
import ElementLogo  from '@/components/elements/element-logo'


export default {
  name: 'block-footer',
  components: {
   ElementLogo,
  },
  data() {
    return {
    }
  }
}
</script>

<style>
footer {
 position: relative;
 margin-top: 100px;
 width: 100%;
 height: 398px;
 padding: 50px 300px 0 100px;
 background: radial-gradient(98.05% 261.61% at 1.95% 3.59%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
 backdrop-filter: blur(40px);
 display: flex;
 align-items: baseline;
 justify-content: space-between;
}



footer .bee-wrap {
  position: absolute;
  width: 36vw;
  height: 36vw;
  left: 57vw;
  top: -25vw;
  z-index: 80;
  background: center / contain no-repeat url("../../assets/images/bees/mobile/animated/regular-turn-right-bee.webp");
  animation: up-down 5s ease-in-out infinite alternate;
  display: none;
}

footer div:nth-child(1) {
 max-width: 279px;
}

footer div:nth-child(1) p {
 margin-top: 24px;
 font-weight: 500;
 line-height: 160%;
}

footer div:nth-child(2) {
 width: 557px;
 display: grid;
 grid-template-columns: max-content max-content max-content;
 gap: 107px;
}

footer ul {
 display: grid;
 grid-template-rows: repeat(6, 24px);
 gap: 16px;
}

footer li span {
 font-weight: 700;
 line-height: 200%;
}

footer li a {
 font-weight: 400;
 font-size: var(--fz-14);
 line-height: 170%;
}

footer, footer li a, footer li span, footer div:nth-child(1) p {
 position: relative;
 z-index: 20;
}


footer .light.light-orange {
 width: 16vw;
 height: 10vw;
 left: 16%;
 top: 50%;
 transform: translateY(-50%);
 filter: blur(160px);
}

footer .light.light-yellow {
 width: 34vw;
 height: 6vw;
 right: 20%;
 top: 50%;
 transform: translateY(-50%);
}

footer .light.light-blue {
  width: 57vw;
  height: 97vw;
  bottom: 3vw;
  left: 25vw;
  display: none;
  opacity: 1;
}

</style>
