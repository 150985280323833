import { createStore } from 'vuex'

const store = createStore({
  state: () => ({
    team: [
      {
        id: 1,
        name: 'Qvant',
        role: 'CEO',
        src: 'qvant',
        description: 'Qvant holds the position of CEO. In addition, he is a co-founder of the company, the circle of his activities includes the supervision of the day-to-day operation of the company, while all operational communications, administrative work and strategic development in general are under the exclusive control of I Qvant. Thinking over the concept of the project and solving all issues to achieve the goal - Qvant, as a co-founder, has made a huge contribution at the stage of development and implementation of the project.'
      },
      {
        id: 2,
        name: 'I Se',
        role: 'Co-founder',
        src: 'i-se',
        description: 'I Se is responsible for all functions within the organization, delegating and distributing the main resource of the company. I Se acts as the key person of the company, accumulating the strategic, financial and operational path of the entire project.'
      },
      {
        id: 3,
        name: 'Jay Halt',
        role: 'СTO, Co-founder',
        src: 'jay-halt',
        description: 'Jay Halt is the CTO and the co-founder of the entire project, the field of action laid its foundation in the creation of the most innovative software complex, in addition, the diversification of work is versatile and applicable in the development of apps, games, contracts, fully covering the scope of the company’s token itself. As co-founder, Jay Halt contributed a great benefit not only in development, but also in the ideological path'
      },
      {
        id: 4,
        name: 'Sj',
        role: 'CVO',
        src: 'sj',
        description: 'SJ, as director of strategy and development, performs a wide range of administrative tasks.  Along with this, he draws up economic development plans that would be most acceptable and suitable for the company. In addition, responsibility for events is also in the CVO’s competence.'
      },
      {
        id: 5,
        name: 'Lem',
        role: 'CMO manager',
        src: 'lem',
        description: '“lem” is responsible for the management of the financial flows, budget planning and distribution. Its functions include the financial stability of the organization, the development of the financial policy of the enterprise, and the control of economical and rational use of enterprise resources.'
      },
      {
        id: 6,
        name: 'Forseti',
        role: 'Lawyer',
        src: 'forseti',
        description: 'One of the behind-the-scenes players of the team is a lawyer (his name), in the crypto industry, and more specifically on dex sites, he is as erudite as possible, which allows him to intertwine legal knowledge with the concept of the project, from his side we get only stable synergy and full control of all legal issues'
      },
      {
        id: 7,
        name: 'Calypso',
        role: 'Business developer',
        src: 'calypso',
        description: 'As an Business Developer Manager , Calypso research and identify new business opportunities - including new markets, growth areas, trends, customers, partnerships, products and services - or new ways of reaching existing markets. Foster and develop relationships with partners. she manages the entire systematization of relationships with partners and provides the best and most profitable offers'
      },
      {
        id: 8,
        name: 'Facepiar',
        role: '3D artist',
        src: 'facepiar',
        description: 'Facepiar plays an important role as a 3d artist in the team. The main task is to implement the artistic and design activities of the entire project. In addition, Facepiar creates and develops animations and nft collection that reveal our project in the best aspects.'
      },
      {
        id: 9,
        name: 'Mk',
        role: 'Project manager',
        src: 'mk',
        description: 'Mk, as a project manager, carries competence and control over many processes of the organization, however, Makka also contributed to the development as a web designer, the main paths are the development of a landing page, website, all interfaces for the complex and programs, all this led to one verdict : being able to perform tasks is one thing  , and to fulfill them with his unique approach in this area is inherent only to Mk'
      },
    ],
  }),
})

export default store