<template>
  <div
    class="overlay"
    v-if="showMenu"
    @click="hideMenu">
    <div
      class="mobile-menu"
      @click.stop>
      <div class="bee-wrap">
        <WidgetAnimatedBee :bee="regularTurnedBee" />
      </div>
      <nav>
        <ul>
          <li class="menu-item">
            <div class="menu-logo">
              <img
                :src="back"
                @click="hideMenu">
              <ElementLogo />
            </div>
          </li>
          <li class="menu-item">
            <router-link @click="hideMenu" to='/'>
              <img :src="home">
              <span>Home</span>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link @click="hideMenu" to='/about'>
              <img :src="about">
              <span>About</span>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link @click="hideMenu" to='/products'>
              <img :src="products">
              <span>Products</span>
            </router-link>
          </li>
          <li class="menu-item local-link" @click="hideMenu">
            <router-link  :to="{ name: 'view-main', hash: '#collections' }">
              <img :src="nft">
              <span>NFT and Game</span>
            </router-link>
          </li>
          <li class="menu-item local-link"  @click="hideMenu">
            <router-link  :to="{ name: 'view-main', hash: '#collections' }">
              <img :src="community">
              <span>Community</span>
            </router-link>
          </li>
          <li class="menu-item dropdown-menu">
            <div class="earn" @click="showEarnMenu">
              <img :src="documents">
              <span :class="[isShowEarn ? linkActiveClass : '']">Documents</span>
              <svg
                class="dropdown-arrow"
                :class="[isShowEarn ? dropdownArrowActiveClass : '']"
                width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.99998 7.60964L0.195312 1.80497L1.13798 0.862305L5.99998 5.7243L10.862 0.862305L11.8046 1.80497L5.99998 7.60964Z" />
              </svg>
            </div>
            <div v-if="isShowEarn === true" class="earn-menu">
              <ul>
                <li>
                  <a href="https://drive.google.com/file/d/1gO15Wy78pm2SKwE5ocPRPQJr686MEwPL/view">Whitepaper</a>
                </li>
                <li><a v-on:click.stop.prevent href="">Gitbook (Soon)</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import ElementLogo from '@/components/elements/element-logo'
import WidgetAnimatedBee from '@/components/widgets/widget-animated-bee'

import regularTurnedBee from '@/assets/images/bees/mobile/animated/regular-turned-bee.webp'
import home from '@/assets/icons/menu/home.svg'
import about from '@/assets/icons/menu/about.svg'
import products from '@/assets/icons/menu/products.svg'
import trades from '@/assets/icons/menu/trades.svg'
import community from '@/assets/icons/menu/community.svg'
import nft from '@/assets/icons/menu/nft.svg'
import documents from '@/assets/icons/menu/documents.svg'
import back from '@/assets/icons/menu/arrow-back.svg'


export default {
  name: 'modal-menu',
  components: {
    ElementLogo,
    WidgetAnimatedBee
  },
  data() {
    return {
      regularTurnedBee,
      home,
      about,
      products,
      trades,
      community,
      nft,
      documents,
      back,
      isShowEarn: false,
      dropdownArrowActiveClass: 'dropdown-arrow_active',
      linkActiveClass: 'link_active',
    }
  },
  props: {
    showMenu: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hideMenu() {
      this.$emit("update:showMenu", false);
    },
    showEarnMenu(e) {
      e.preventDefault()
      this.isShowEarn = !this.isShowEarn
      console.log(this.isShowEarn)
    },
  },
};
</script>


<style >
.mobile-menu {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 70vw;
  height: 100%;
  z-index: 102;
  box-shadow: inset 6.25333px -6.25333px 6.25333px rgba(182, 182, 182, 0.1),
    inset -6.25333px 6.25333px 6.25333px rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(13.132px);
  padding: 8vw 0;
}

.mobile-menu .bee-wrap {
  width: 26vw;
  height: 26vw;
  top: 50%;
  right: -1vw;
  position: absolute;
  transform: translate(50%, -50%);
}

.mobile-menu nav {
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  position: relative;
  width: 100%;
  height: 100%;
}

.mobile-menu nav::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.mobile-menu ul {
  display: grid;
  grid-auto-flow: row;
  gap: .2vw;
}


.mobile-menu li.menu-item:not(.dropdown-menu) {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2vw 0;
}

.mobile-menu li.menu-item img {
  width: 5vw;
  height: auto;
  object-fit: contain;
}

.mobile-menu li.menu-item:nth-child(1) img {
  width: 3vw;
  height: auto;
}

.mobile-menu .logo img {
  width: 5vw !important;
  height: auto !important;
}

.mobile-menu li.menu-item span:not(.logo span) {
  margin-left: 3vw;
  font-weight: 700;
  font-size: 4.6vw;
  letter-spacing: 0.2vw;
}

.mobile-menu .dropdown-arrow {
  margin-left: 1.2vw;
  fill: var(--color-white);
}

.mobile-menu li.menu-item a:not(.logo-link),
.mobile-menu li.menu-item .local-link,
.mobile-menu .earn,
.mobile-menu .menu-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5vw 5vw;
  width: 100%;
}

.mobile-menu .menu-item:not(.mobile-menu .menu-item:nth-child(1), .local-link) .router-link-active {
  background: rgba(255, 221, 44, 0.16);
}

.mobile-menu li.menu-item:nth-child(6) {
  display: block;
}

.mobile-menu .earn-menu {

  height: 30vw;
  margin-left: 10vw;
  position: relative;
}

.mobile-menu .earn-menu ul {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: space-between;
  height: 80%;
}

.mobile-menu .earn-menu ul li, .mobile-menu .earn-menu ul a {
  font-weight: 500;
  font-size: 4vw;
  line-height: 120%;
  color: #FFFFFF;
}
</style>
