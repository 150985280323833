<template>
  <main class="trades">
    <BlockHero>
      <img :src="heroBee" alt="bee" />
    </BlockHero>
    <section class="trades-info">
      <div class="container">
        <div class="trades-info-wrap">
          <div class="trades-info-blocks">
            <WidgetList :tradesData="data" title="History"/>
            <WidgetList :tradesData="data" title="Bids"/>
          </div>
        </div>
      </div>
    </section>
    <BlockCollection :heading="heading" :hasButton="false" />
    <BlockBottom />
  </main>
</template>

<script>
import BlockHero from '@/components/blocks/block-hero';
import BlockCollection from '@/components/blocks/block-collection'
import BlockBottom from '@/components/blocks/block-bottom'
import WidgetList from '@/components/widgets/widget-list'

import heroBee from '@/assets/images/bee-trades.png';

export default {
  name: 'view-trades',
  components: {
    BlockHero,
    BlockCollection,
    BlockBottom,
    WidgetList
  },
  data: () => {
    return {
      heroBee,
      data: [
        {
          id: 1,
          autor: 'CameronWilliamson',
          price: 'Bid 1 ETH',
          date: '2021 June 20, 06:30 AM',
        },
        {
          id: 2,
          autor: 'GuyHawkins',
          price: 'Bid 1 ETH',
          date: '2021 June 20, 06:30 AM',
        },
        {
          id: 3,
          autor: 'RalphEdwards',
          price: 'Bid 1 ETH',
          date: '2021 June 20, 06:30 AM',
        },
        {
          id: 4,
          autor: 'AnnetteBlack',
          price: 'Bid 1 ETH',
          date: '2021 June 20, 06:30 AM',
        },
        {
          id: 5,
          autor: 'CameronWilliamson',
          price: 'Bid 1 ETH',
          date: '2021 June 20, 06:30 AM',
        },
        {
          id: 6,
          autor: 'GuyHawkins',
          price: 'Bid 1 ETH',
          date: '2021 June 20, 06:30 AM',
        },
        {
          id: 7,
          autor: 'RalphEdwards',
          price: 'Bid 1 ETH',
          date: '2021 June 20, 06:30 AM',
        },
        {
          id: 8,
          autor: 'AnnetteBlack',
          price: 'Bid 1 ETH',
          date: '2021 June 20, 06:30 AM',
        },
      ],
      heading: {
        title: 'More Works by Luke Henderson',
        btnText: 'Filter & Sort'
      }
    }
  },
  mounted(){
    document.title = 'Trades — BeePro'
  }
};
</script>

<style>
.trades-info {
  padding-top: 8vw;
  padding-bottom: 7vw;
}

.trades-info-blocks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: auto;
}


@media (max-width: 770px) {
  .trades .collection-block .oval-button {
    display: none;
  }

  .trades .collection-block .block-heading {
    justify-content: center;
  }

  .trades-info h4 {
    font-size: 4.4vw;
  }

  .trades-info {
    padding-top: 200px;
    padding-bottom: 12vw;
  }

  .trades-info-blocks {
    grid-template-columns: unset;
    grid-template-rows: 1fr 1fr;
    gap: 3.4rem;
  }

  .trades-info-block {
    height: 80vw;
    width: 80vw;
  }

  .trades-info-item {
    grid-template-columns: 21.8vw auto;
    column-gap: 2.3vw;
    align-items: center;
  }

  .trades-info-item-descr {
    height: 50%;
  }

  .trades-info-item-descr h5 {
    font-size: 3.6vw;
  }

  .trades-info-item-descr span, .trades-info-item-descr p {
    font-size: 2.8vw;
  }
}
</style>