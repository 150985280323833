<template>
 <div class="scroll-wrap">
  <div 
    class="slide" 
    @mousedown="startDragging" 
    @mouseup="stopDragging" 
    @mouseleave="stopDragging" 
    @mousemove="dragging"
    >
    <slot></slot>
  </div>
 </div>
</template>

<script>


export default {
  name: 'element-slider',
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data: () => {
    return {
      mouseDown: false,
      startX: 0,
      scrollLeft: 0
    };
  },
  methods: {
    startDragging(e) {
      if (window.innerWidth <= 720) return
      this.mouseDown = true;
      let slider = document.querySelectorAll(`.scroll-wrap`)
      this.startX = e.pageX - slider[this.id].offsetLeft;
      this.scrollLeft = slider[this.id].scrollLeft;
    },
    stopDragging() {
      if (window.innerWidth <= 720) return
      this.mouseDown = false;
    },
    dragging(e) {
      if (window.innerWidth <= 720) return
      if(!this.mouseDown) { return; }
      let slider = document.querySelectorAll(`.scroll-wrap`)
      const x = e.pageX - slider[this.id].offsetLeft;
      const scroll = x - this.startX;
      slider[this.id].scrollLeft = this.scrollLeft - scroll;
    }
  },
};

</script>

<style>
 .scroll-wrap {
 width: 100%;
 height: 100%;
 overflow: hidden;
 overflow-x: scroll; 
 float:left;
}
.slide{
 width: 4000px;
 height: 100%;
 float:left;
 font-size: 15px;
 font-family:arial;
 padding-left: 1rem;
 cursor: pointer;
}

.slide img {
 width: 100%;
 height: 100%;
 object-fit: contain;
} 

.scroll-wrap {
 -ms-overflow-style: none; 
 scrollbar-width: none;
}

.scroll-wrap::-webkit-scrollbar {
 display: none;
}
</style>