<template>
  <section class="roadmap">
    <div class="container">
      <div class="roadmap-wrap">
        <ElementHeading title="Roadmap" />
      </div>
    </div>
    <div class="container-slider">
      <ElemetnScroll :id="1">
        <img :src="roadmap" alt="">
      </ElemetnScroll>
    </div>
  </section>
</template>

<script>
import ElementHeading from '@/components/elements/element-heading'
import ElemetnScroll from '@/components/elements/element-scroll'

import roadmap from '@/assets/images/roadmap/roadmap.png'


export default {
  name: 'main-roadmap',
  components: {
    ElementHeading,
    ElemetnScroll
  },
  data: () => {
    return {
      roadmap,
    };
  },
};
</script>

<style>
.roadmap {
  padding: 2vw 0 100px;
}

.roadmap .container-slider {
  height: 810px;
}

@media(max-width: 720px) {
  .roadmap .slide {
    width: 1500px !important;
  }

  .roadmap .container-slider {
    height: 410px;
  }
}
</style>