<template>
  <section class="seed">
    <div class="container">
      <div class="seed-wrap" id="seed">
        <video autoplay playsinline class="seed-video" muted ref="video">
          <source :src="require(`@/assets/videos/seed-main.mp4`)" type="video/mp4">
          <source :src="require(`@/assets/videos/seed-main.webm`)" type="video/webm">
        </video>
        <Transition name="link-fade-in">
          <a v-show="isLinkShow" class="seed-link"
            href="https://www.pinksale.finance/private-sale/0xc6A4abaeeE98b68640F4F5d0CDc7E741b3B64F68/?chain=BSC">
            <span>Buy</span>
          </a>
        </Transition>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  data() {
    return {
      isLinkShow: false
    }
  },
  // mounted() {
  //   setTimeout(() => {
  //     this.isLinkShow = true
  //   }, 5000)
  // }
  mounted() {
    this.play()
    setTimeout(() => {
      this.isLinkShow = true
    }, 5000)
    setTimeout(() => {
      this.$refs.video.pause()
    }, 12000)
  },
  methods: {
    play() {
      this.$refs.video.play()
    }
  }
}
</script>

<style>
.seed {
  padding: 10vw 0 0;
}

.seed-wrap {
  position: relative;
  width: 100%;
  height: 60vw;
}

.seed-wrap video {
  transition: all ease .7s;
  width: 100%;
  height: 100%;
}

.seed-link {
  position: absolute;
  bottom: 5%;
  left: 30%;
  padding: 2rem 5.125rem !important;
  background: var(--black-color);
  border-radius: .5rem;
  border: 1px solid var(--special-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 50;
}

.seed-link span {
  font-weight: 700;
  color: var(--white-color);
  font-size: 2rem;
  line-height: 1;
}

.link-fade-in-enter-active,
.link-fade-in-leave-active {
  animation: bounce-in 2s;
}

.seed-mobile-bg {
  display: none;
  width: 100%;
  height: auto;
  object-fit: contain;
}

@media (min-width: 1980px) {
  .seed-wrap {
    height: 750px;
  }
}

@media (max-width: 876px) {
  .seed-link span {
    font-size: 1rem;
  }

  .seed-link {
    padding: 1rem 2.56rem !important;
    border-radius: .3rem;
    bottom: -10%;
  }
}

@media (max-width: 570px) {
  .seed-wrap {
    height: 67vw;
  }

  .seed-link span {
    font-size: .5rem;
  }

  .seed-link {
    padding: .5rem 1.28rem !important;
  }
}
</style>