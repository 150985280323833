import { createRouter, createWebHistory } from "vue-router";

import ViewMain from '@/views/view-main.vue'
import ViewAbout from '@/views/view-about.vue'
import ViewProducts from '@/views/view-products.vue'
import ViewTrades from '@/views/view-trades.vue'
import ViewTeam from '@/views/view-team.vue'
import VueScrollTo from 'vue-scrollto'

const routes = [
  {
    name: 'view-main',
    path: '/',
    component: ViewMain
  },
  {
    name: 'view-about',
    path: '/about',
    component: ViewAbout
  },
  {
    name: 'view-products',
    path: '/products',
    component: ViewProducts
  },
  {
    name: 'view-trades',
    path: '/trade',
    component: ViewTrades
  },
  {
    name: 'view-team',
    path: '/team/:id',
    props: true,
    component: ViewTeam
  }
]

export default createRouter({
  history: createWebHistory(),
  scrollBehavior(to) {
    if (to.hash) {
      VueScrollTo.scrollTo(to.hash, 700);
      return {
        el: to.hash,
      }
    }
  },
  routes
})