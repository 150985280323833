<template>
 <section class="teaser">
   <div class="container">
     <div class="teaser-wrap">
       <ElementHeading title="Teaser" />

       <div class="teaser-picture">
         <iframe  width="100%" height="100%" src="https://www.youtube.com/embed/Pn_1v5V8yrk?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
       </div>
       
       <span class="light light-blue"></span>
       <span class="light light-orange"></span>
       <span class="light light-yellow"></span>
     </div>
   </div>
 </section>
</template>

<script>

import ElementHeading from '@/components/elements/element-heading'
import teaserImg from '@/assets/images/teaser/teaser-img.png'

export default {
 name: 'main-teaser',
 components: {
   ElementHeading,
 },
  data() {
    return {
      teaserImg,
     
    }
  },
  methods: {
        onReady() {
            this.$refs.youtube.playVideo()
        },
    },
 
}
</script>

<style>
.teaser {
padding: 200px 0 100px;
}

.teaser-picture {
 margin: 60px auto 0;
 position: relative;
 max-width: 115%;
 max-width: 1356px;
 background: linear-gradient(
   149deg,
   rgba(255, 255, 255, 0) 0%,
   rgba(110, 121, 113, 1) 100%
 );
 padding: 18px 21px 18px 19px;
 border: 1px solid #979797;
 z-index: 20;
 height: calc(196px + (557 + 557 * .3) * ((100vw - 375px) / 1980));
}

.teaser-picture .video {
 object-fit: contain;
 width: 100%;
 height: 100%;
 z-index: 20;
}

.teaser .light.light-blue {
width: 24vw;
height: 22vw;
top: 40%;
right: 0px;
}

.teaser .light.light-orange {
width: 22vw;
height: 22vw;
left: 42vw;
top: 10vw;
}

.teaser .light.light-yellow {
width: 24vw;
height: 8vw;
right: 20%;
bottom: 5%;
}

@media (min-width: 1980px) {
  .teaser-picture {
    height: 769px;
  }
}
</style>