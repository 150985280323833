<template>
  <Subscribe />
  <Footer />
</template>

<script>
import Subscribe from '@/components/blocks/block-subscribe';
import Footer from '@/components/blocks/block-footer';

export default {
  name: 'block-bottom',
  components: {
    Subscribe,
    Footer
  }
}
</script>