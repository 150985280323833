<template>
  <div class="card">
    <img 
      :src="cardBgImg" 
      class="card-bg" 
    />
    <div class="card-wrap">
      <div class="card-preview">
        <img :src="require(`@/assets/images/collection/${collection.src}`)" alt="">
      </div>
      <div class="card-body">
        <!-- <div class="card-autor">
          <div></div>
          <span>{{ collection.autor }}</span>
        </div> -->
        <h6>
          {{ collection.name }}
        </h6>
        <div class="card-info">
          <div>
            <p>Current Bid</p>
            <span>
              {{ collection.price }}
            </span>
          </div>
          <div>
            <p>Auction ending in</p>
            <span>
              {{ collection.time }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import cardBgImg from "@/assets/images/collection/collection-bg.png";

export default {
  name: 'element-card',
  data: () => {
    return {
      cardBgImg,
    };
  },
  props: {
    collection: {
      type: Object,
      required: true,
      default() {
        return {
          collections: [
            {
              id: 1,
              name: "Hakuna Mutuala",
              autor: "Yerikho Christian",
              price: "0.4435 ETH",
              time: "10:12:32",
            },
            {
              id: 2,
              name: "Hakuna Mutuala",
              autor: "Yerikho Christian",
              price: "0.4435 ETH",
              time: "10:12:32",
            },
            {
              id: 3,
              name: "Hakuna Mutuala",
              autor: "Yerikho Christian",
              price: "0.4435 ETH",
              time: "10:12:32",
            },
          ],
        };
      },
    },
  },
};
</script>

<style>
.collection-block .card {
  position: relative;
  max-width: 24rem;
}

.collection-block .card-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.collection-block .card-wrap {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 27rem 19rem;
}

.collection-block .card-body {
  padding: 0 1.81rem 7.9rem 0.75rem;
}

.collection-block .card-autor {
  display: flex;
  align-items: center;
}

.collection-block .card-autor div {
  width: 2rem;
  height: 2rem;
  background: var(--grey-color);
  border-radius: 100%;
  margin-right: 0.5rem;
}

.collection-block .card-autor span,
.collection-block .card-info p {
  font-size: 0.875rem;
  font-weight: 500;
}

.collection-block h6 {
  margin-top: 1.835rem;
  font-size: 2rem;
  font-weight: 700;
  color: var(--special-color);
  text-transform: capitalize;
  font-feature-settings: "pnum" on, "lnum" on;
}

.collection-block .card-info {
  margin-top: 2rem;
  width: 100%;
  height: 70px;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  gap: .5rem;
}

.collection-block .card-info div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.collection-block .card-info span {
  font-size: 1.5rem;
  font-weight: 700;
  font-feature-settings: "pnum" on, "lnum" on;
  min-width: 86px;
}

.card-preview {
  position: relative;
}

.card-preview img {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 58%;
  height: auto;
  object-fit: contain;
}
</style>