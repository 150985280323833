<template>
  <main class="products">
    <BlockHero class="extra-mb">
      <ElementSearch />
    </BlockHero>
    <BlockCollection />
    <BlockBottom />
  </main>
</template>

<script>
import BlockHero from '@/components/blocks/block-hero'
import BlockCollection from '@/components/blocks/block-collection'
import BlockBottom from '@/components/blocks/block-bottom'

import ElementSearch from '@/components/elements/element-search'

export default {
  name: 'view-products',
  components: {
    BlockHero,
    ElementSearch,
    BlockCollection,
    BlockBottom
  },
  mounted(){
    document.title = 'Products — BeePro'
  }
}
</script>

<style>
.extra-mb {
  margin-bottom: 26.2rem;
}
</style>