<template>
  <div>
    <BlockHeader 
      @open-wallet="openWallet"
      @open-menu="openMenu"
    />

    <router-view @open-wallet="openWallet"></router-view>
    
    

    <Transition v-if="isMobileWaletView" 
      enter-active-class="animate__animated animate__fadeInUp" 
      leave-active-class="animate__animated animate__fadeOutDown"
    >
      <ModalWallet v-model:showWallet="walletVisible"/> 
    </Transition>

    <ModalWallet v-else  v-model:showWallet="walletVisible"/> 

    <Transition 
      enter-active-class="animate__animated animate__fadeInLeft" 
      leave-active-class="animate__animated animate__fadeOutLeft"
    >
      <ModalMenu v-model:showMenu="menuVisible"/>
    </Transition>
  </div>
</template>

<script>
import { onMounted } from 'vue'

import BlockHeader from '@/components/blocks/block-header'
import ModalWallet from '@/components/modals/modal-wallet'
import ModalMenu from '@/components/modals/modal-menu'

export default {
  setup() {
    onMounted(() => {
      console.log('mounted in the composition api!')
    })
  },
  name: 'App',
  components: {
    BlockHeader,
    ModalWallet,
    ModalMenu
  },
  data() {
    return {
      walletVisible: false,
      menuVisible: false,
      isMobileWaletView: false
    }
  },
  methods: {
    handler: function(e) {
      e.preventDefault();
      alert('=)')
    },
    openWallet(arg) {
      this.walletVisible = arg
    },
    openMenu(arg) {
      this.menuVisible = arg
      this.selectAnimate()
    },
    
    selectAnimate() {
      this.show = !this.show;
    },

    handleResize() {
      if (window.innerWidth <= 769) this.isMobileWaletView = true
      else this.isMobileWaletView = false
    },
  
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap");

:root {
  /* colors */

 --color-body: #000000;
 --color-white: #FFFFFF;
 --black-color: #000000;
 --grey-color:#E7E7E7;
 --second-grey-color:#D9D9D9;
 --special-color: #FFDD2C;
 --text-input-color:#818181;
 

  /* font-size */

 --fz-14: 14px;
 --fz-16: 16px;
 --fz-22: 22px;
 --fz-24: 24px;
 --fz-32: 32px;
 --fz-48: 48px;
 --fz-55: 55px;
 --fz-64: 64px;
}

html {
  height: 100%;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: var(--color-white);
  font-family: "Montserrat", sans-serif;
  font-size: var(--fz-16);
}

a {
  text-decoration: none;
  cursor: pointer;
}

li {
  list-style-type: none;
}

ul {
  margin-left: 0;
  padding-left: 0;
}

body {
  background: var(--color-body);
}

section {
  position: relative;
}

input:focus,
input:active,
button:focus,
button:active {
  outline: none;
}

.container {
  padding: 0 100px;
  height: 100%;
  max-width: 1980px;
  margin: 0 auto;
}

.main {
  overflow: hidden;
}

.light {
  position: absolute;
  z-index: 5;
  border-radius: 100%;
}

.light.light-blue {
  background: #085575; 
  opacity: 0.8;
  filter: blur(200px);
}

.light.light-orange {
  background: #f78d2e; 
  filter: blur(200px);
}

.light.light-yellow {
   background: #ffdd2c; 
  filter: blur(140px);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.71);
  z-index: 100;
}

.dropdown-arrow {
 margin-left: 8px;
 fill: var(--color-white);
}

.dropdown-arrow.dropdown-arrow_active {
 fill: var(--special-color);
}

header a.router-link-active:not(.local-link), .link_active {
  text-shadow: 0 0 .7px var(--special-color), 0 0 .9px var(--special-color);
  color: var(--special-color);
}

footer li a:hover {
  -webkit-mask-image: linear-gradient(-75deg, rgba(0,0,0,.6) 30%, #000 50%, rgba(0,0,0,.6) 70%);
  -webkit-mask-size: 200%;
  animation: shine 2s infinite;
}

.-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.-enter, .fade-leave-to  {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.1s ease-in-out;
}

.slide-fade-enter-active {
  transition-delay: .1s;
  animation: bounce-in .1s reverse;
}

.slide-fade-leave-active {
  transition: all 0.1s;
  filter: blur(10px);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
}

.animated-bee {
  width: 100%;
  height: auto;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  
}

.animation-config-1 {
  animation: up-down 7s ease-in-out infinite alternate;
}

.bee-wrap {
  width: 100%;
  height: auto;
  object-fit: contain;
}

</style>