<template>
  <button class="button">{{ text }}</button>
</template>

<script>

export default {
  name: 'bnutton-regular',
  props: {
    text: {
      type: String,
      default: 'Log in'
    }
  }
}
</script>

<style>
.button {
  position: relative;
  font-weight: 700;
  line-height: 200%;
  padding: 12px 48px;
  background: var(--special-color);
  border-radius: 8px;
  color: var(--black-color);
  border: none;
  cursor: pointer;
  display: block;
  z-index: 50;
}

@media (max-width: 1440px) {
  .button {
    font-weight: 700;
    line-height: 200%;
    padding: 10px 28px;
    background: var(--special-color);
    border-radius: 8px;
    color: var(--black-color);
    border: none;
    cursor: pointer;
  }
}

/* @media (min-width: 1980px) {
  .button {
    font-weight: 700;
    line-height: 200%;
    padding: 1vw 2vw;
    background: var(--special-color);
    border-radius: 8px;
    color: var(--black-color);
    border: none;
    cursor: pointer;
    font-size: 1vw;
  }
} */
</style>