<template>
  <img :src="bee" alt="bee" class="animated-bee" :class="animationSettingsClass">
</template>


<script>

export default {
  name: 'widget-animated-bee',
  props: {
    bee: {
      required: false,
    },
    animationSettingsClass: {
      default: ''
    }
  }
}

</script>

<style>

.animated-bee {
  width: 100%;
  height: auto;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  
}

/* .animation-config-1 {
  animation: up-down 7s ease-in-out infinite alternate;
} */


@keyframes nudge {

  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 5%);
  }

  80% {
    transform: translate(0, -5%);
  }
}
</style>

