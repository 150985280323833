<template>
 <section class="hero-block">
  <div class="container">
   <div class="hero-wrap">
    <div 
      class="hero-content" 
      v-bind:class="[ isSmallImg ? smallImglinkActiveClass : '']"
      >
      <slot></slot>
     </div>
    </div>
   </div>

   <div class="lights-wrap">
      <span class="light light-blue"></span>
      <span class="light light-orange"></span>
      <span class="light light-yellow"></span>
    </div>
 </section>
</template>

<script>
export default {
  name: 'block-hero',
  data() {
    return {
      smallImglinkActiveClass: 'hero-content_small-img'
    }
  },
  props: {
    isSmallImg: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
 .hero-block .hero-wrap {
  padding: 100px 0 100px;
  position: relative;
  height: 100%;
  width: 100%;

 }

 .hero-block .lights-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
 }

 .hero-block .hero-wrap, .hero h1, .hero h2, .hero-descr, .hero-btn {
  position: relative;
  z-index: 20;
 } 

 .hero-block {
  position: relative;
  content: '';
  width: 100%;
  min-height: 28rem;
  background:radial-gradient(98.05% 261.61% at 1.95% 3.59%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(40px);
  box-sizing: border-box;
  z-index: 10;
 }


 .hero-block .hero-content {
  position: relative;
  height: 100%;
  width: 100%;
 }

 .hero-block .hero-content img:not(.bee-left, .bee-right) {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 18rem;
  height: auto;
  object-fit: contain;
  z-index: 50;  
 }

 .hero-block .hero-content_small-img img {
  width: 28rem !important;
 }

 .hero-block .light-blue {
  width: 26vw;
  height: 28vw;
  top: 77px;
  left: 60%;
  filter: blur(200px);
  opacity: 1;
 }

 .hero-block .light-orange {
  width: 28vw;
  height: 35vw;
  left: 8vw;
  top: 0;
 }
 
 .hero-block .light-yellow {
  width: 22vw;
  height: 22vw;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
 }
</style>