<template>
  <main class="main">
    <MainHero />
    <MainSeed/>
    <MainTeaser />
    <MainBeendexer />
    <MainFunctions />
    <MainTokenomics @open-wallet="openWallet" />
    <BlockCollection id="collections"/>
    <MainTeam/>
    <MainRoadmap />
    <MainContact id="community"/>
    <BlockBottom />
  </main>
</template>

<script>
import MainHero from '@/components/main/main-hero'
import MainSeed from '@/components/main/main-seed'
import MainTeaser from '@/components/main/main-teaser'
import MainBeendexer from '@/components/main/main-beendexer'
import MainFunctions from '@/components/main/main-functions'
import MainTokenomics from '@/components/main/main-tokenomics'
import BlockCollection from '@/components/blocks/block-collection'
import MainTeam from '@/components/main/main-team'
import MainRoadmap from '@/components/main/main-roadmap'
import MainContact from '@/components/main/main-contact'
import BlockBottom from '@/components/blocks/block-bottom'

export default {
  name: 'view-main',
  components: {
    MainHero,
    MainSeed,
    MainTeaser,
    MainBeendexer,
    MainFunctions,
    MainTokenomics,
    BlockCollection,
    MainTeam,
    MainRoadmap,
    MainContact,
    BlockBottom
  },
  data() {
    return {
      walletVisible: false,
      menuVisible: false
    }
  },
  methods: {
    openWallet() {
      this.$emit('open-wallet', true)
    }
  },
  mounted(){
    document.title = 'BeePro'
  }
};
</script>
