<template>
  <div>
    <h4> {{ title }} </h4>
    <div class="trades-info-block">
      <div class="trades-info-block-wrap">
        <ul>
          <li class="trades-info-item" v-for="{autor, id, price, date} in tradesData" :key="id">
            <img :src="pentagon" />
            <div class="trades-info-item-descr">
              <h5> {{ autor }} </h5>
              <div>
                <span> {{ price }} </span>
                <p> {{ date }} </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import pentagon from '@/assets/icons/trades/pentagon.svg';

export default {
  name: 'widget-list',
  data() {
    return {
      pentagon
    }
  },
  props: {
    tradesData: {
      type: Object
    },
    title: {
      type: String
    }
  }
}

</script>

<style>
.trades-info h4 {
  font-weight: 700;
  font-size: 3vw;
  text-transform: capitalize;
  font-feature-settings: "pnum" on, "lnum" on;
  text-align: center;
  position: relative;
}

.trades-info-block {
  position: relative;
  height: 30vw;
  width: 30vw;
  background: radial-gradient(98.05% 261.61% at 1.95% 3.59%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(40px);
  border-left: 2px solid rgba(255, 255, 255, 0.5);
  margin: 2rem auto 0;
}

.trades-info-block-wrap {
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  position: relative;
  height: 100%;
  width: 100%;
}

.trades-info-block::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0));
  z-index: 50;
}

.trades-info-block::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0));
  z-index: 50;
}

.trades-info-block-wrap::-webkit-scrollbar-track {
  box-shadow: inset 0 0 .375rem rgba(255, 221, 44, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 1rem;
}

.trades-info-block-wrap::-webkit-scrollbar-thumb {
  background: var(--special-color);
  box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
}

.trades-info-block-wrap::-webkit-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

.trades-info-block ul {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-auto-rows: auto;
  gap: 2.2vw;
  padding: 0.8vw 1.8vw 0.8vw 1.4vw;
}

.trades-info-item {
  display: grid;
  grid-template-columns: 4.8vw auto;
  column-gap: 1.3vw;
}

.trades-info-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.trades-info-item-descr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 3.9vw;
}

.trades-info-item-descr h5 {
  font-weight: 700;
  font-size: 1.6vw;
  text-transform: capitalize;
  font-feature-settings: "pnum" on, "lnum" on;
}

.trades-info-item-descr div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trades-info-item-descr span,
.trades-info-item-descr p {
  font-weight: 500;
  font-size: 1.1vw;
}

.trades-info-item-descr span {
  color: var(--special-color);
}
</style>