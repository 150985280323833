<template>
  <section class="tokenomics">
    <div class="container">
      <div class="tokenomics-wrap">
        <ElementHeading title="Tokenomics" />
        <div class="tokenomics-master-cell tokenomics-content-mobile">
          <div class="tokenomics-cell tokenomics-cell-sub tokenomics-center">
            <div>
              <span>4.2%</span>
              <span>SEED ROUND</span>
            </div>
          </div>
          <div class="tokenomics-cell tokenomics-cell-sub tokenomics-top-right">
            <div>
              <span>5%</span>
              <span>PUBLIC ROUND</span>
            </div>
          </div>
          <div class="tokenomics-cell tokenomics-cell-sub tokenomics-right">
            <div>
              <span>4.2%</span>
              <span>private round</span>
            </div>
          </div>
          <div class="tokenomics-cell tokenomics-cell-sub tokenomics-bottom-right">
            <div>
              <span>21.9%</span>
              <span>Treasury</span>
            </div>
          </div>
          <div class="tokenomics-cell tokenomics-cell-sub tokenomics-bottom-left">
            <div>
              <span>21.9%</span>
              <span>EVENTS</span>
            </div>
          </div>
          <div class="tokenomics-cell tokenomics-cell-sub tokenomics-left">
            <div>
              <span>12%</span>
              <span>Team</span>
            </div>
          </div>
          <div class="tokenomics-cell tokenomics-cell-sub tokenomics-top-left">
            <div>
              <span>30.8%</span>
              <span>Liquidity pool</span>
            </div>
          </div>
        </div>
        <span class="light light-orange"></span>
      </div>
    </div>
    <div class="container-slider">
      <ElemetnScroll name="tokenomics">
        <img :src="tokenomics" alt="">
      </ElemetnScroll>
    </div>
    <div class="container">
      <div class="tokenomics-wrap">
        <ButtonRegular @click="openWallet" text="Buy Token" />
      </div>
    </div>
  </section>
</template>

<script>
import ElementHeading from '@/components/elements/element-heading'
import ElemetnScroll from '@/components/elements/element-scroll'
import ButtonRegular from '@/components/buttons/button-regular'

import tokenomics from '@/assets/images/tokenomics/tokenomics.png'

export default {
  name: 'main-tokenomics',
  components: {
    ElementHeading,
    ElemetnScroll,
    ButtonRegular
  },
  data: () => {
    return {
      tokenomics,
    };
  },
  methods: {
    openWallet() {
      this.$emit('open-wallet', true)
    }
  }
};
</script>

<style>
.tokenomics {
  padding: 80px 0 8vw;
}

.tokenomics-btn {
  display: block;
  margin: 20px auto 0;
  padding: 15px 51px;
  background: linear-gradient(13.34deg, #fe5500 16.35%, #fe8900 85.45%);
  box-shadow: inset 2.39333px -2.39333px 2.39333px rgba(213, 93, 0, 0.4),
    inset -2.39333px 2.39333px 2.39333px rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(2.154px);
  font-weight: 700;
  font-size: 30.1613px;
  line-height: 39px;
  letter-spacing: 0.100538px;
  color: var(--black-color);
  cursor: pointer;
  border-radius: 6px;
  text-transform: uppercase;
  border: none;
}

.tokenomics-master-cell {
  position: relative;
  width: 80vw;
  height: 46vw;
  margin: 6.3vw auto;
  z-index: 20;
}

.tokenomics-cell::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.tokenomics-cell-sub {
  position: absolute;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.tokenomics-cell-sub div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 20;
  width: 100%;
  height: 100%;
  text-align: center;
}

.tokenomics-cell-sub span {
  width: 60%;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.4vw;
}

.tokenomics-center div {
  justify-content: flex-start;
  margin-top: 55%;
}

.tokenomics-cell-sub span:nth-child(2) {
  margin-top: 3%;
}

.tokenomics-center {
  top: 50%;
  left: 50%;
  width: 19.6vw;
  height: 19.6vw;
}

.tokenomics-center::before {
  background: center / contain no-repeat url("../../assets/images/tokenomics/center-cell.png");
}

.tokenomics-top-right {
  top: 25%;
  left: 61%;
  width: 11.2vw;
  height: 11.2vw;
}

.tokenomics-top-right::before {
  background: center / contain no-repeat url("../../assets/images/tokenomics/small-dark-cell.png");
}

.tokenomics-right {
  top: 50%;
  left: 71%;
  width: 15.4vw;
  height: 15.4vw;
}

.tokenomics-right::before {
  background: center / contain no-repeat url("../../assets/images/tokenomics/orange-cell.png");
}

.tokenomics-bottom-right {
  top: 82%;
  left: 61%;
  width: 17vw;
  height: 17vw;
}

.tokenomics-bottom-right::before {
  background: center / contain no-repeat url("../../assets/images/tokenomics/yellow-cell.png");
}

.tokenomics-bottom-left {
  top: 83%;
  left: 38%;
  width: 19.4vw;
  height: 19.4vw;
}

.tokenomics-bottom-left::before {
  background: center / contain no-repeat url("../../assets/images/tokenomics/orange-cell.png");
}

.tokenomics-left {
  top: 50%;
  left: 31%;
  width: 12.6vw;
  height: 12.6vw;
}

.tokenomics-left::before {
  background: center / contain no-repeat url("../../assets/images/tokenomics/medium-dark-cell.png");
}

.tokenomics-top-left {
  top: 17%;
  left: 37%;
  width: 19.4vw;
  height: 19.4vw;
}

.tokenomics-top-left::before {
  background: center / contain no-repeat url("../../assets/images/tokenomics/orange-cell.png");
}

.tokenomics .light-orange {
  width: 31vw;
  height: 46vw;
  left: -18vw;
  top: 1%;
  opacity: .6;
}

.tokenomics .button {
  margin: 3vw auto 0;
}

.container-slider {
  height: 600px;
}

@media(min-width: 1980px) {
  .tokenomics .scroll-wrap {
   display: flex;
   align-items: center;
   justify-content: center;
  }
}
</style>