import { createApp } from 'vue'
import VueGtag from "vue-gtag";

import App from './App.vue'

import router from '@/router/router.js'
import store from '@/store'

import VueScrollTo from 'vue-scrollto'
import Vue3TouchEvents from 'vue3-touch-events'

import './assets/style/media.css'
import './assets/style/animation.css'
import 'animate.css'


const app = createApp(App)
app.use(store)
app.use(router)
app.use(Vue3TouchEvents)
app.use(VueScrollTo, {
 container: "body",
 duration: 500,
 easing: "ease",
 offset: 0,
 force: true,
 cancelable: true,
 onStart: false,
 onDone: false,
 onCancel: false,
 x: false,
 y: true
})
app.use(VueGtag, {
  config: { id: "G-HYS68L3P8M" }
}, router)
app.mount('#app')
